import { useLanguage } from "hooks";
import React from "react";
import { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import "./Cards.scss";

function Cards({ url, title, subTitle }) {
  const [show, setShow] = useState(false);
  const { translation } = useLanguage();

  return (
    <div id="cards" className="my-5">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-custom-modal-styling-title"
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{subTitle}</p>
        </Modal.Body>
      </Modal>
      <Card>
        <Card.Img
          variant="pills"
          src={url}
          alt={url}
          width="160"
          height="140"
          className="align-self-center mt-4"
        />
        <Card.Body className="mt-1">
          <Card.Title className="text-primary fw-bolder">{title}</Card.Title>
          <Card.Text className="sub-title">{subTitle}</Card.Text>
          <Card.Text className="readMore-button">
            <span role="button" onClick={() => setShow(true)} className="text-primary text-decoration-underline">
             {translation.ReadMore}
            </span>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Cards;
