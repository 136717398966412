import React, { useMemo } from "react";
import { Layout, Cards } from "components";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useCountDown, useLanguage } from "hooks";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import "./Home.scss";
import "swiper/css";
import "swiper/css/navigation";
import { FiInstagram } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";

const SlideItem = ({ slide, index }) => {
  const swiperSlide = useSwiperSlide();

  const getScaleY = useMemo(() => {
    if (swiperSlide.isActive) {
      return 1.3;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 1.15;
    }

    if (swiperSlide.isVisible) {
      return 1.05;
    }

    return 0.2;
  }, [
    swiperSlide.isActive,
    swiperSlide.isNext,
    swiperSlide.isPrev,
    swiperSlide.isVisible,
  ]);

  const getTiming = useMemo(() => {
    if (swiperSlide.isActive) {
      return 0.25;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 0.35;
    }

    return 0.35;
  }, [swiperSlide.isActive, swiperSlide.isNext, swiperSlide.isPrev]);

  const getZindex = useMemo(() => {
    if (swiperSlide.isActive) {
      return 1300;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 1200;
    }

    return 1100;
  }, [swiperSlide.isActive, swiperSlide.isNext, swiperSlide.isPrev]);

  return (
    <div>
      <img
        src={slide}
        alt="slide"
        className={`${swiperSlide.isActive ? "z-10000" : "z-1"}`}
        style={{
          position: "relative",
          transition: `all ${getTiming}s ease-in-out`,
          transform: `scale(${getScaleY}, ${getScaleY})`,
          zIndex: getZindex,
          opacity: swiperSlide.isVisible === true ? 1 : 0,
          borderRadius: "15px",
        }}
      />
    </div>
  );
};

function Home() {
  const { language, translation } = useLanguage();
  const [days, hours, minutes, seconds] = useCountDown("2024-10-10");

  const slides = [
    "/Images/screens1/1.jpeg",
    "/Images/screens1/2.jpeg",
    "/Images/screens1/3.jpeg",
    "/Images/screens1/4.jpeg",
    "/Images/screens1/5.jpeg",
    "/Images/screens1/6.jpeg",
    "/Images/screens1/7.jpeg",
    "/Images/screens1/24.jpeg",
    "/Images/screens1/8.jpeg",
    "/Images/screens1/9.jpeg",
    "/Images/screens1/10.jpeg",
    "/Images/screens1/11.jpeg",
    "/Images/screens1/12.jpeg",
    "/Images/screens1/13.jpeg",
    "/Images/screens1/14.jpeg",
    "/Images/screens1/15.jpeg",
    "/Images/screens1/16.jpeg",
    "/Images/screens1/17.jpeg",
    "/Images/screens1/18.jpeg",
    "/Images/screens1/19.jpeg",
    "/Images/screens1/20.jpeg",
    "/Images/screens1/21.jpeg",
    "/Images/screens1/22.jpeg",
    "/Images/screens1/23.jpeg",
  ];

  return (
    <Layout>
      <div id="home-page" className="">
        <Container>
          <div id="Features" className="py-5">
            <Row className="mt-4">
              <Col xs={12}>
                <h1 className="title">
                  {translation.WithMedicoJoYouWillGetHugeAdvantages}
                </h1>
              </Col>
            </Row>
            <div>
              <Row className="mt-5 align-items-center">
                <Col xs={12} md={6}>
                  <img
                    src="/Images/clock2.gif"
                    alt="clock"
                    width="100%"
                    height="100%"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-5 mt-md-0">
                  <Row>
                    <Col xs={12}>
                      <h1 className="text-primary fw-bolder">
                        {translation.DontMissYourStudyPlans}
                      </h1>
                    </Col>
                    <Col xs={12}>
                      <h2 className="fw-bolder">
                        {translation.SetYourCountdownToUpcomingEventsAndExams}
                      </h2>
                    </Col>
                    {/* <Col xs={12}>
                        <h4 className="mt-3">{translation.paragraph}</h4>
                      </Col> */}
                    <Col xs={12} className="mt-4">
                      <div className="clock">
                        <Row>
                          <Col xs={3} data-title="Days">
                            <div className="time">
                              <h1>{days}</h1>
                            </div>
                            <div className="mt-3">
                              <h6 className="fw-bolder">{translation.Days}</h6>
                            </div>
                          </Col>
                          <Col xs={3} data-title="Hours">
                            <div className="time">
                              <h1>{hours}</h1>
                            </div>
                            <div className="mt-3">
                              <h6 className=" fw-bolder">
                                {translation.Hours}
                              </h6>
                            </div>
                          </Col>
                          <Col xs={3} data-title="Minutes">
                            <div className="time">
                              <h1>{minutes}</h1>
                            </div>
                            <div className="mt-3">
                              <h6 className=" fw-bolder">
                                {translation.Minutes}
                              </h6>
                            </div>
                          </Col>
                          <Col xs={3} data-title="Seconds">
                            <div className="time">
                              <h1>{seconds}</h1>
                            </div>
                            <div className="mt-3">
                              <h6 className=" fw-bolder">
                                {translation.Seconds}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="slider-content mt-5">
              <Row>
                <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                  <Cards
                    url={"/Images/1.png"}
                    title={translation.QuestionBanks}
                    subTitle={translation.PargQuestionBanks}
                  />
                </Col>
                <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                  <Cards
                    url={"/Images/2.png"}
                    title={translation.VideosAndCourses}
                    subTitle={translation.PargVideosAndCourses}
                  />
                </Col>
                <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                  <Cards
                    url={"/Images/3.png"}
                    title={translation.Flashcards}
                    subTitle={translation.PargFlashcards}
                  />
                </Col>
                <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                  <div className="mt-5">
                    <Card className="card-img border-0">
                      <img
                        src="/Images/man.png"
                        alt="rightImgCard"
                        className="image mt-1"
                      />
                    </Card>
                  </div>
                </Col>
                {/* <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <div className="mt-5">
                      <Card className="card-img border-0">
                        <img
                          src="/Images/leftImgCard.png"
                          alt="leftImgCard"
                          className="image mt-3 mt-xl-1"
                        />
                      </Card>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/notes.png"}
                      title={translation.EBooks}
                      subTitle={translation.PargEBooks}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/rating.png"}
                      title={translation.PointsAndBalance}
                      subTitle={translation.PargPointsAndBalance}
                      width="200"
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                    <Cards
                      url={"/Images/news.png"}
                      title={translation.NewsAndArticles}
                      subTitle={translation.PargNewsAndArticles}
                    />
                  </Col> */}
              </Row>
            </div>
          </div>
        </Container>
        <div className="screens" id="UI-screens">
          <Container className="p-0">
            {/* fluid="md" */}
            <div className="text-center">
              <Row>
                <Col xs={12}>
                  <div className="title-screen">
                    <h1 data-title={translation.WatchOurUIPresentation}>
                      {translation.WatchOurUIPresentation}
                    </h1>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="h-100 py-5">
                    <Swiper
                      dir="ltr"
                      watchSlidesProgress
                      centeredSlides
                      centeredSlidesBounds
                      centerInsufficientSlides
                      // onActiveIndexChange={onSlideChangeHandler}
                      // onInit={onSlideChangeHandler}
                      // onSlideChange={onSlideChangeHandler}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        576: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 5,
                        },
                      }}
                      spaceBetween={0}
                      slidesPerGroup={1}
                      loop={true}
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {slides.map((slide, idx) => (
                        <SwiperSlide virtualIndex={idx}>
                          <SlideItem slide={slide} index={idx} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="download" id="Download-app">
          <Container>
            <div className="download-content">
              <Row>
                <Col xs={12}>
                  <h1
                    className="text-center fw-bolder"
                    data-title={translation.DownloadOurApp}
                  >
                    {translation.DownloadOurApp}
                  </h1>
                </Col>
              </Row>
              <Row className="mt-5 justify-content-center">
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className="header-download-btns-container d-flex flex-wrap">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.darisni.app&pcampaignid=web_share"
                      className="text-black"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="header-download-btn">
                        <div>
                          <img src="/Images/google1.webp" alt="google" />
                        </div>
                        <div>
                          <h6 className="header-download-btn-subtitle">
                            {translation.getItOn}
                          </h6>
                          <h5 className="header-download-btn-title fw-bolder">
                            {translation.GooglePlay}
                          </h5>
                        </div>
                      </div>
                    </a>
                    <img
                      src="/Images/android.png"
                      alt="android"
                      className="qrimg"
                      height="100%"
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className="header-download-btns-container d-flex flex-wrap mt-5 mt-sm-0">
                    <a
                      href="https://apps.apple.com/jo/app/darisni-%D8%AF%D8%B1%D8%B3%D9%86%D9%8A/id6479218970"
                      className="text-black"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="header-download-btn">
                        <div>
                          <img src="/Images/Apple.png" alt="Apple" />
                        </div>
                        <div>
                          <h6 className="header-download-btn-subtitle">
                            {translation.getItOn}
                          </h6>
                          <h5 className="header-download-btn-title fw-bolder">
                            {translation.AppleStore}
                          </h5>
                        </div>
                      </div>
                    </a>
                    <img
                      src="/Images/apple1.png"
                      alt="apple1"
                      className="qrimg"
                      height="100%"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="news" id="contact-us">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="footer-logo">
                  {language === "ar" ? (
                    <img src="/Images/logoDarisni.png" alt="logo" />
                  ) : (
                    <img src="/Images/logoDarisni.png" alt="logo" />
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <div className="contact" dir="ltr">
                  {/* <div>
                    <a href="tel:+962 79 999 35 63">
                      <div className="d-flex ">
                        <BsTelephone className="icons" />
                        <h4 className="text-white">+962 79 999 35 63</h4>
                      </div>
                    </a>
                  </div> */}
                  {/* <div>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex ">
                        <FiFacebook className="icons" />
                        <h4>Darisni</h4>
                      </div>
                    </a>
                  </div> */}
                  <div>
                    <a
                      href="https://www.instagram.com/darissni"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex ">
                        <FiInstagram className="icons" />
                        <h4>@Darisni</h4>
                      </div>
                    </a>
                  </div>
                  {/* <div>
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex ">
                        <FiTwitter className="icons" />
                        <h4>@Darisni</h4>
                      </div>
                    </a>
                  </div> */}
                  <div className="d-flex">
                    <a
                      href="mailto:info@darisni.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <AiOutlineMail className="icons" />
                        <h4>info@darisni.com</h4>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
