import React from "react";
import { Container, Row, Col, Navbar, Nav, Button } from "react-bootstrap";
import { useLanguage } from "hooks";
import "./Header.scss";

function Header() {
  const { language, translation, handleSwitchLanguage } = useLanguage();

  const navLinks = [
    {
      title: translation.features,
      link: "#Features",
    },
    {
      title: translation.download,
      link: "#Download-app",
    },
    {
      title: translation.uIscreens,
      link: "#UI-screens",
    },
    // {
    //   title: translation.contactUs,
    //   link: "#contact-us",
    // },
  ];
  return (
    <div id="header" className="py-3">
      <Container>
        <Row>
          <Col xs={4} sm={4} md={3} lg={2} xl={2} className="align-self-center">
            <img
              src="/Images/logoDarisni.png"
              alt="logo"
              className="img12"
              width="100%"
              height="auto"
            />
          </Col>
          <Col
            xs={8}
            sm={8}
            md={9}
            lg={10}
            xl={10}
            className="align-self-center"
          >
            <div className="contant">
              <Row className="align-items-center">
                <Col xs={12} lg={10} xl={11}>
                  <div className="header-navigation">
                    <Navbar
                      bg="transparent"
                      expand="lg"
                      className="navbar justify-content-end"
                    >
                      <Navbar.Toggle
                        color="white"
                        aria-controls="basic-navbar-nav"
                      />
                      <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="text-end px-3 py-2 rounded-3 justify-content-end"
                      >
                        <Nav className="text-start">
                          {navLinks.map((item, idx) => (
                            <Nav.Link href={item.link} key={idx}>
                              {item.title}
                            </Nav.Link>
                          ))}
                        </Nav>
                        <div className="d-block d-lg-none mt-3 text-start">
                          <Button
                            // as={Link}
                            // to={`/${language === "ar" ? "en" : "ar"}`}
                            onClick={handleSwitchLanguage}
                            variant="primary"
                            className="w-100"
                          >
                            <div>
                              <h5 className="text-white ">
                                {language === "ar" ? "English" : "عربي"}
                              </h5>
                            </div>
                          </Button>
                        </div>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </Col>
                {/* <Col xs={12} lg={4} xl={3} className="d-none d-lg-block"></Col> */}
                <Col xs={12} lg={2} xl={1} className="lang d-none d-lg-block">
                  <div className="langButton">
                    <Button onClick={handleSwitchLanguage} variant="primary">
                      <div>
                        <h5 className="text-white ">
                          {language === "ar" ? "English" : "عربي"}
                        </h5>
                      </div>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          {/*  mt-lg-0 */}
          <Col
            xs={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            className="align-self-center"
          >
            <Row>
              <Col xs={12} className="">
                <h2 className="text-white">
                  {
                    translation.theBestAppForMakingYouSucceedAndPassYourMedicalExams
                  }
                </h2>
              </Col>
              <Col xs={12} className="mt-4 start">
                <h2 className="text-white fw-bolder">
                  {translation.DownloadOurApp}
                </h2>
                <div className="header-download-btns-container d-flex flex-wrap mt-3">
                  {/* <div className="qr-div text-center">
                    <img
                      src="/Images/qrCode.png"
                      alt="qrCode"
                      width={200}
                      className="qrimg"
                    />
                  </div> */}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.darisni.app&pcampaignid=web_share"
                    className="text-black"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="header-download-btn">
                      <div>
                        <img src="/Images/google1.webp" alt="google" />
                      </div>
                      <div>
                        <h6 className="header-download-btn-subtitle">
                          {translation.getItOn}
                        </h6>
                        <h5 className="header-download-btn-title fw-bolder">
                          {translation.GooglePlay}
                        </h5>
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://apps.apple.com/jo/app/darisni-%D8%AF%D8%B1%D8%B3%D9%86%D9%8A/id6479218970"
                    className="text-black"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="header-download-btn">
                      <div>
                        <img src="/Images/Apple.png" alt="Apple" />
                      </div>
                      <div>
                        <h6 className="header-download-btn-subtitle">
                          {translation.getItOn}
                        </h6>
                        <h5 className="header-download-btn-title fw-bolder">
                          {translation.AppleStore}
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xs={6}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            className="d-none d-md-block"
          >
            <div className="hero">
              {language === "en" ? (
                <img src="Images/finalheroen.png" alt="finalheroen" />
              ) : (
                <img src="Images/finalheroar.png" alt="finalheroar" />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
